<template>
  <v-container>
    <v-row dense justify="center" no-gutters>
      <v-col cols="12" sm="10">
        <v-row dense no-gutters>
          <v-col>
            <h1 class="text-center toolbar--text">{{ $t('About') }}</h1>
            <p class="text-center">
              Blockchainmetrics Inc. is a data analytics platform, Provides fundamental analysis of on-chain & off-chain Blockchain transactional data.<br/>
              Our goal is to privide complete insights into crypto market price data and blockchain transactional data using AI and Machine Learning.
            </p>
          </v-col>
        </v-row>
        <v-row dense class="about-img" />
        <v-row dense justify="center">
          <v-col cols="12" sm="6" class="text-center" >
            <iframe
              id="ytplayer"
              type="text/html"
              class="promo-video"
              src="https://www.youtube.com/embed/FKGlxEf8LFg"
              frameborder="0"
            >
            </iframe>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name:'About',
}
</script>

<style lang="scss" scoped>
.about-img {
  background-image: url(~@/assets/images/page-img.png);
  background-position: 50% center;
  background-repeat: no-repeat;
  max-width: 100%;
  background-size: contain;
  margin: auto;
  height: 300px;
}

.promo-video {
  max-width: 100%;
  height: 360px;
  width: 100%;
  @media (max-width: 600px) {
    padding: 0 10px;
  }
}
</style>
